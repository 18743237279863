import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { withContext } from "~context";
import { PageLayout } from "~components/templates/page-layout";
import { Button } from "~components/utils/button";
import { SEO } from "~components/templates/seo";
import * as styles from "./index.module.scss";

const Component: React.FC = () => {
  const data = useStaticQuery(graphql`
    query ContactErrorPage {
      messenger_logo: file(relativePath: { eq: "messenger-logo.svg" }) {
        extension
        publicURL
      }
    }
  `);
  return (
    <>
      <SEO meta={[{ name: "robots", content: "noindex" }]} />
      <PageLayout
        paddingPattern="larger-than-header"
        meta={{ title: "送信に失敗しました" }}
      >
        <div className={styles.Error}>
          <p className={styles.Error_Sorry}>
            {`正常に送信することができませんでした。
            お手数ですがcontact@flatt.techに直接ご連絡いただくか、
            執行役員豊田のメッセンジャーに直接ご連絡ください。
          `}
          </p>
          <Link to="/contact" className={styles.Error_LinkToContact}>
            <Button buttonSize="Large">お問い合わせフォームへ</Button>
          </Link>
          <div className={styles.Error_Messenger}>
            <a
              href="https://m.me/K2toyoda"
              className={styles.Error_MessengerLinkToToyoda}
            >
              <img src={data.messenger_logo.publicURL} alt="" />
              <span>執行役員豊田のMessengerに繋がります！</span>
            </a>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default withContext(Component);
