// extracted by mini-css-extract-plugin
export var Button = "index-module--Button--rDmOm";
export var Button__Default = "index-module--Button__Default--a7TxQ";
export var Button__Disabled = "index-module--Button__Disabled--KVscb";
export var Button__Gradient = "index-module--Button__Gradient--w-FLy";
export var Button__Kenro = "index-module--Button__Kenro--EteTg";
export var Button__Kenro_Download = "index-module--Button__Kenro_Download--R+DVE";
export var Button__Kenro_Reverse = "index-module--Button__Kenro_Reverse--fJARM";
export var Button__Large = "index-module--Button__Large--HUsyB";
export var Button__Medium = "index-module--Button__Medium--vJJ-f";
export var Button__Secondary = "index-module--Button__Secondary--yjQJs";
export var Button__Shisho = "index-module--Button__Shisho--vFtOy";
export var Button__Shisho_Reverse = "index-module--Button__Shisho_Reverse--QOzQv";
export var Button__Small = "index-module--Button__Small--jDmB5";
export var Button__Xlarge = "index-module--Button__Xlarge--u+n0y";