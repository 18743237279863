import React, { useMemo, FC, MouseEvent } from "react";
import clsx from "clsx";
import * as styles from "./index.module.scss";

type ButtonTheme =
  | "Default"
  | "Secondary"
  | "Gradient"
  | "Kenro"
  | "Kenro_Reverse"
  | "Kenro_Download"
  | "Shisho"
  | "Shisho_Reverse";
type ButtonSize = "Small" | "Medium" | "Large" | "Xlarge";

type Props = {
  className?: string;
  buttonTheme?: ButtonTheme;
  buttonSize?: ButtonSize;
  type?: "submit" | "button";
  disabled?: boolean;
  children: React.ReactNode;
  onClick?(e: MouseEvent<HTMLButtonElement>): void;
};

export const Button: FC<Props> = ({
  buttonTheme = "Default",
  buttonSize = "Medium",
  type = "button",
  disabled = false,
  onClick,
  children,
  className,
}) => {
  const buttonClass = useMemo(() => {
    return clsx(
      className,
      styles.Button,
      [disabled ? styles.Button__Disabled : styles[`Button__${buttonTheme}`]],
      styles[`Button__${buttonSize}`],
    );
  }, [buttonTheme, buttonSize, disabled, className]);

  if (type === "button") {
    return (
      <button
        type="button"
        className={buttonClass}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
  return (
    <button
      type="submit"
      className={buttonClass}
      onSubmit={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
